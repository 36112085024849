import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import(/* webpackChunkName: "Home" */ "../views/Home.vue"),
  },

  {
    path: "/application-form",
    name: "ApplicationForm",
    component: () =>
      import(
        /* webpackChunkName: "ApplicationForm" */ "../views/ApplicationForm.vue"
      ),

    meta: {
      authorize: true,
      allowed: store.getters.GET_APPLICATION_FORM,
    },
  },

  {
    path: "/not-serviceable",
    name: "NotServiceable",
    component: () =>
      import(
        /* webpackChunkName: "NotServiceable" */ "../views/NotServiceable.vue"
      ),
    meta: {
      authorize: true,
      allowed: store.getters.GET_NOT_SERVICEABLE,
    },
  },

  {
    path: "/submitted",
    name: "Submitted",
    component: () =>
      import(/* webpackChunkName: "Submitted" */ "../views/Submitted.vue"),
  },

  {
    path: "/failed",
    name: "Failed",
    component: () =>
      import(/* webpackChunkName: "Failed" */ "../views/Failed.vue"),
  },

  {
    path: "/review-application",
    name: "ReviewApplicationForm",
    component: () =>
      import(
        /* webpackChunkName: "ReviewApplication" */ "../views/ReviewApplication.vue"
      ),
  },

  {
    path: "/payment-selection",
    name: "PaymentSelection",
    component: () =>
      import(
        /* webpackChunkName: "PaymentSelection" */ "../views/PaymentSelection.vue"
      ),
  },

  {
    path: "/terms-and-conditions",
    name: "TermsAndCondition",
    component: () =>
      import(/* webpackChunkName: "TermsAndCondition" */ "../views/TCPage.vue"),
  },

  {
    path: "**",
    name: "PageNotFound",
    component: () =>
      import(/* webpackChunkName: "PageNotFound" */ "../views/404.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const { authorize } = to.meta;
  // store.getters.GET_NOT_SERVICEABLE
  // const isLoggedIn = store.getters.GET_LOGIN_STATUS;
  const notServiceable = store.getters.GET_NOT_SERVICEABLE;
  const applicationForm = store.getters.GET_APPLICATION_FORM;

  if (authorize) {
    if (to.path == "/not-serviceable") {
      if (!notServiceable) {
        return next({ path: "/" });
      }
    }

    if (to.path == "/application-form") {
      if (!applicationForm) {
        return next({ path: "/" });
      }
    }
  }
  next();
});

export default router;
