export default {
  state: {
    notServiceable: true,
    applicationForm: false,
  },

  mutations: {
    SET_NOT_SERVICEABLE(state, payload) {
      state.notServiceable = payload;
      // state.status = "prelogin";
    },

    SET_APPLICATION_FORM(state, payload) {
      state.applicationForm = payload;
      // state.status = "prelogin";
    },
  },

  actions: {
    ADD_NOT_SERVICEABLE({ commit }, payload) {
      return new Promise((resolve, reject) => {
        try {
          commit("SET_NOT_SERVICEABLE", payload);
          resolve();
        } catch (error) {
          reject(error);
        }
      });
    },

    ADD_APPLICATION_FORM({ commit }, payload) {
      return new Promise((resolve, reject) => {
        try {
          commit("SET_APPLICATION_FORM", payload);
          resolve();
        } catch (error) {
          reject(error);
        }
      });
    },
  },

  getters: {
    // isLoggedIn: state => !!state.token,
    GET_NOT_SERVICEABLE: (state) => state.notServiceable,
    GET_APPLICATION_FORM: (state) => state.applicationForm,
  },
};
