import axios from "axios";

export const API = axios.create({
  baseURL: `https://ibas.s2s.ph/api`,
  headers: {
    "Content-Type": "application/json",
    // Authorization: "rlxnXB1v3FTZr3KYz71yGNILkKMZetOt4ED3DD+r",
    Authorization: "0+dyNZ+4VlFh/CuFjcA2cdyeITp9xx+j9IqUSf9d",
    // "wsc-timestamp": d.getTime(),
  },
});

export const API3 = axios.create({
  baseURL: `https://ibas.s2s.ph/api`,
  headers: {
    "Content-Type": "application/json",
  },
});

export const API2 = axios.create({
  baseURL: "https://load.s2s.ph/api",
});

export const googleApiKey = "AIzaSyAAgfYa38ByEGIf26UpQPrNRyU3IGKrA1s";

export const LOCATION_API = axios.create({
  baseURL: "https://location.s2s.ph/api",
  headers: {
    "Content-Type": "application/json",
  },
});

export const SMS = axios.create({
  baseURL: `https://otp.s2s.ph/api`,
  headers: {
    "Content-Type": "application/json",
  },
  auth: {
    username: "S2SOTP",
    password: "ccjVa5Rz9tdLwNoIUZsUYQ==",
  },
});
