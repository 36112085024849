import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import {
  API,
  API2,
  googleApiKey,
  LOCATION_API,
  API3,
  SMS,
} from "../http-common";

// import "sweetalert2/dist/sweetalert2.min.css";
import VueSweetalert2 from "vue-sweetalert2";

// If you don't need the styles, do not connect
import "sweetalert2/dist/sweetalert2.min.css";

import "bootstrap/dist/css/bootstrap.min.css";
import Vuelidate from "vuelidate";
import VueTheMask from "vue-the-mask";

import "bootstrap/dist/js/bootstrap.min.js";
import "@/assets/css/style.scss";

import VeeValidate from "vee-validate";
import * as VueGoogleMaps from "vue2-google-maps";
import "bootstrap-icons/font/bootstrap-icons.css";

import VueLoading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

Vue.use(VueLoading);
Vue.use(VeeValidate);
Vue.use(VueSweetalert2);
Vue.use(VueTheMask, { masked: false });
Vue.config.productionTip = false;
Vue.use(Vuelidate);

Vue.use({
  install(Vue) {
    Vue.prototype.$api = API;
    Vue.prototype.$api2 = API2;
    Vue.prototype.$location = LOCATION_API;
    Vue.prototype.$api3 = API3;
    Vue.prototype.$sms = SMS;
  },
});

Vue.use(VueGoogleMaps, {
  load: {
    key: googleApiKey,
    libraries: "places",
  },
});

Vue.use({
  install(Vue) {
    Vue.prototype.$api = API;

    Vue.prototype.$api.interceptors.request.use(
      (config) => {
        let token = store.getters.token;
        if (token) {
          const d = new Date();
          config.headers = Object.assign(
            {
              "wsc-timestamp": d,
            },
            config.headers
          );
        }
        return config;
      },
      (error) => {
        console.log(error);
        return Promise.reject(error);
      }
    );
  },
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
