export default {
  state: {
    // center: { lat: 45.508, lng: -73.587 },
    center: { lat: 14.5935087, lng: 121.1001506 },
    currentPlace: null,
    markers: [],
    places: [],
  },

  mutations: {
    SET_CENTER(state, payload) {
      state.center = payload;
      // state.status = "prelogin";
    },

    SET_CURRENT_PLACE(state, payload) {
      state.currentPlace = payload;
      // state.status = "prelogin";
    },

    // SET_LOGIN(state, user) {
    //   state.user = user;
    //   state.status = "loggedin";
    // },

    // SET_LOGOUT(state) {
    //   state.user = null;
    //   state.status = "";
    // },

    // SET_LOAD(state, data) {
    //   state.load = data;
    // },
  },

  actions: {
    ADD_CENTER({ commit }, payload) {
      return new Promise((resolve, reject) => {
        try {
          commit("SET_CENTER", payload);
          resolve();
        } catch (error) {
          reject(error);
        }
      });
    },

    ADD_CURRENT_PLACE({ commit }, payload) {
      return new Promise((resolve, reject) => {
        try {
          commit("SET_CURRENT_PLACE", payload);
          resolve();
        } catch (error) {
          reject(error);
        }
      });
    },

    // ADD_LOGIN({ commit }, user) {
    //   return new Promise((resolve, reject) => {
    //     try {
    //       commit("SET_LOGIN", user);
    //       resolve();
    //     } catch (error) {
    //       reject(error);
    //     }
    //   });
    // },

    // ADD_LOGOUT({ commit }) {
    //   return new Promise((resolve, reject) => {
    //     try {
    //       commit("SET_LOGOUT");
    //       resolve();
    //     } catch (error) {
    //       reject(error);
    //     }
    //   });
    // },

    // ADD_LOAD({ commit }, payload) {
    //   return new Promise((resolve, reject) => {
    //     try {
    //       commit("SET_LOAD", payload);
    //       resolve();
    //     } catch (error) {
    //       reject(error);
    //     }
    //   });
    // },
  },

  getters: {
    // isLoggedIn: state => !!state.token,
    GET_CENTER: (state) => state.center,
    GET_CENTER_PLACE: (state) => state.currentPlace,
    // GET_USER: (state) => state.user,
    // GET_LOAD: (state) => state.load,
  },
};
