import Vue from "vue";
import Vuex from "vuex";
import SharedData from "./modules/SharedData";
import MapsModules from "./modules/MapsModules";
import RouteAuth from "./modules/RouteAuth";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    SharedData,
    MapsModules,
    RouteAuth,
  },
  plugins: [createPersistedState()],
});
