export default {
  state: {
    preData: null,
    refNumber: "",
    applicationFormData: {},
    affiliateCode: "",
    dataPack: {},
    isCod: false,
    codResponse: {},
    otpVerified: false,
  },

  mutations: {
    SET_PREDATA(state, payload) {
      state.preData = payload;
    },

    SET_REFNUMBER(state, payload) {
      state.refNumber = payload;
    },

    SET_APPLICATIONFORMDATA(state, payload) {
      state.applicationFormData = payload;
    },

    SET_AFFILIATECODE(state, payload) {
      state.affiliateCode = payload;
    },

    SET_DATAPACK(state, payload) {
      state.dataPack = payload;
    },

    SET_IS_COD(state, payload) {
      state.isCod = payload;
    },

    SET_COD_RESPONSE(state, payload) {
      state.codResponse = payload;
    },

    SET_OTP_VERIFIED(state, payload) {
      state.otpVerified = payload;
    },
  },

  actions: {
    ADD_PREDATA({ commit }, payload) {
      return new Promise((resolve, reject) => {
        try {
          commit("SET_PREDATA", payload);
          resolve();
        } catch (error) {
          reject(error);
        }
      });
    },

    ADD_REFNUMBER({ commit }, payload) {
      return new Promise((resolve, reject) => {
        try {
          commit("SET_REFNUMBER", payload);
          resolve();
        } catch (error) {
          reject(error);
        }
      });
    },

    ADD_APPLICATIONFORMDATA({ commit }, payload) {
      return new Promise((resolve, reject) => {
        try {
          commit("SET_APPLICATIONFORMDATA", payload);
          resolve();
        } catch (error) {
          reject(error);
        }
      });
    },

    ADD_AFFILIATECODE({ commit }, payload) {
      return new Promise((resolve, reject) => {
        try {
          commit("SET_AFFILIATECODE", payload);
          resolve();
        } catch (error) {
          reject(error);
        }
      });
    },

    ADD_DATAPACK({ commit }, payload) {
      return new Promise((resolve, reject) => {
        try {
          commit("SET_DATAPACK", payload);
          resolve();
        } catch (error) {
          reject(error);
        }
      });
    },

    ADD_IS_COD({ commit }, payload) {
      return new Promise((resolve, reject) => {
        try {
          commit("SET_IS_COD", payload);
          resolve();
        } catch (error) {
          reject(error);
        }
      });
    },

    ADD_COD_RESPONSE({ commit }, payload) {
      return new Promise((resolve, reject) => {
        try {
          commit("SET_COD_RESPONSE", payload);
          resolve();
        } catch (error) {
          reject(error);
        }
      });
    },

    ADD_OTP_VERIFIED({ commit }, payload) {
      return new Promise((resolve, reject) => {
        try {
          commit("SET_OTP_VERIFIED", payload);
          resolve();
        } catch (error) {
          reject(error);
        }
      });
    },
  },

  getters: {
    // isLoggedIn: state => !!state.token,
    GET_PREDATA: (state) => state.preData,
    GET_REFNUMBER: (state) => state.refNumber,
    GET_APPLICATIONFORMDATA: (state) => state.applicationFormData,
    GET_AFFILIATECODE: (state) => state.affiliateCode,
    GET_DATAPACK: (state) => state.dataPack,
    GET_ISCOD: (state) => state.isCod,
    GET_CODRESPONSE: (state) => state.codResponse,
    GET_OTPVERIFID: (state) => state.otpVerified,
  },
};
